((factory) ->
  if typeof define is "function" and define.amd
    define ['jquery', 'jquery.railsremote'], factory
  else
    factory jQuery
) ($) ->

  $('body').append(HandlebarsTemplates['newsletter/popout']({}))

  # Add newsletter popout in from asset templates
  $('body').on "click", "#signup-alert", ()->
    $('#newsletter-modal').show()

  $('#close-newsletter-modal').on "click", ()->
    $('#newsletter-modal').hide()

  trySignup = ($email, button) ->
    $.ajax
      type: 'POST'
      url: '/newsletter-subscribe-modal'
      dataType: 'json'
      data: $email
      success: (data, status, xhr) ->
        $(button).attr("disabled", false);
        Alert.success data.message,
          shiftContent: true,
          autoHide: true
        Newsletter.rememberSignedUp()
        $('#newsletter-modal').hide()
        if $('#signup-alert').parent('[data-js-header-alerts-container]').length > 0
          Alert.closeAlert($('#signup-alert'), true)
        else
          Alert.closeAlert($('#signup-alert'), false)
      error: (xhr, status, err) ->

        $(button).attr("disabled", false);
        if $('#signup-alert').parent('[data-js-header-alerts-container]').length > 0
          Alert.closeAlert($('#signup-alert'), true)
        else
          Alert.closeAlert($('#signup-alert'), false)
        Alert.error XhrHelper.handleError(xhr).error,
          shiftContent: true,
          autoHide: false,
          buttonId: 'signup-alert'
        $('#signup-alert-close').on "click", ()->
          Newsletter.rememberHide()
          Newsletter.hide()
        $('#newsletter-modal').hide()

  recordSignupLocation = (location, $email) ->
    userInfo = $.extend($email, "source": location)
    $.ajax
      type: 'POST'
      url: '/signup-tracker'
      data: {'email': $email.val(), 'location': location, 'page': window.location.href}

  signUpSubmitted = ($email, location, button) ->
    emailRegex = /.+@.+/i;
    event.preventDefault()
    if !$email.val().match(emailRegex)
      $email.focus()
      alert 'Oops, it looks like that email address is not valid. Please enter a valid email address and try again'
    else
      $(button).attr("disabled", true);
      recordSignupLocation(location, $email)
      trySignup($email, button)

  # Newsletter sign up for popout promotion
  $('form#modal-newsletter-form').on 'submit', (event) ->
    signUpSubmitted $(this).find('input[type=email]'), 'popup', '#newsletter-popout-subscribe'

  # Newsletter sign up for newsletter signup page
  $('form#sg-embedded-subscribe-form').on 'submit', (event) ->
    signUpSubmitted $(this).find('input[type=email]'), 'popup', '#sg-embedded-subscribe'

  # Newsletter popout when user scrolls down the page
  $('[data-close-newsletter-popout]').on 'click', (event) ->
    $('#newsletter-modal').hide()

  # Newsletter sign up for blog
  $('[data-js-form="blog_form"]').on 'submit', (event) ->
    signUpSubmitted $(this).find('input[type=email]'), 'blog', '.subscribe-button'

window.Newsletter =
  rememberSignedUp: () ->
    # Don't show this user a newsletter sign up again for 2 years
    $.cookie('newsletter', 'signedup', { expires: 730, path: '/' })
  rememberHide: () ->
    # They've hidden the box so don't want to sign up, ask them again in 3 months
    $.cookie('newsletter', 'hidden', { expires: 90, path: '/' })
  hide: () ->
    # Alert is closed by existing alert code, we also want to ensure the popout is closed
    $('#newsletter-modal').remove()
  show: () ->
    # If a referral flash promo is shown on this page, don't bombard the user with
    # newsletter prompts as well
    if window.ReferralFlashPromos && window.ReferralFlashPromos.shown
      return

    totalScroll = 0
    setTimeout ->
      scrollPosition = $(window).scrollTop()
      onScroll = () ->
        # if 50 pixels from the bottom, then popout the newsletter in
        if $(document).height() - $(window).scrollTop() <= $(window).height() + 50
          showNewsletterOnce()
        else
          totalScroll += Math.abs(scrollPosition - $(window).scrollTop())
          scrollPosition = $(window).scrollTop()
          showNewsletterOnce() if totalScroll > ($(document).height() * 0.3) || totalScroll > 2000
      showNewsletterOnce = () ->
        # Check if cookie exists again before showing in case user signed up using another form
        Alert.info 'Enjoying this article? Join the other industry pioneers who trust us for their monthly realtime insight. <a>Subscribe now</a>',
          shiftContent: true,
          autoHide: false,
          buttonId: 'signup-alert' unless $.cookie('newsletter')
        $('#signup-alert-close').on "click", ()->
          Newsletter.rememberHide()
          Newsletter.hide()
        $(window).off 'scroll', onScroll
      $(window).on 'scroll', onScroll
    , 1000
  showUnlessCookied: () ->
    onPopupPage = window.location.href.search(/\/(concepts|design-patterns|compare|blog|resources|research)\/./g) > -1
    onNoMobilePopupPage = window.location.href.search(/\/(blog)\/./g) > -1
    Newsletter.show() unless $.cookie('newsletter') || !onPopupPage || (MobileHelpers.usingMobileView() && onNoMobilePopupPage)
