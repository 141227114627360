/* eslint-disable */

/**
 * All things jquery (extensions/plugins)
 *
 * @note jquery already globally provided => config/webpack/custom.js
 * BUT ONLY FOR MODULES, not for "scripts". But some of
 * these are not modules, are regular scripts,
 * so webpacks providePlugin can't expose jquery to them.
 * Also providePlugin doens't make jquery globally exposed to window,
 * only for modules.
 */

// [1] expose jquery to a global window variable
import './jquery-expose';

/**
 * JQUERY-MIGRATE
 *
 * Fix deprecated methods in jQuery >=3.0
 * Deprecated methods to refactor!
 *
 *    $.fn.load
 *    $.browser
 *    $.ready
 *
 * @link https://jquery.com/upgrade-guide/3.0/#breaking-change-load-unload-and-error-removed
 * @link https://stackoverflow.com/questions/37738732/jquery-3-0-url-indexof-error
 * @note [1] - the main version outputs log to console
 */
if (process.env.NODE_ENV === 'development') {
  $.migrateTrace = false; // shorter warnings, enable for full stack trace
  import('jquery-migrate'); // [1]
} else {
  import('jquery-migrate/dist/jquery-migrate.min.js');
}

// order matters
import '@vendor/javascripts/jquery.sparkline';

/**
 * JQUERY-UJS
 *
 * "unobtrusive javascript"
 * @todo [1] - Check if suggested package `rail-ujs` replacement works
 */
import 'jquery-ujs'; // [1];

/**
 * ANCIENT INLINE JQUERY PLUGINS
 *
 * @todo If found compatible npm module, replace it here
 */
import '@vendor/javascripts/autosize';
import '@vendor/javascripts/jquery.railsremote';
import '@vendor/javascripts/jquery.cookie';
import '@vendor/javascripts/jquery.flashMessages';
