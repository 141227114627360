# loads CodeMirror module, its modes, addons and styles;
import CodeMirror from "global/plugins/codemirror/codemirror"
import AppKeysSelector from "components/app-keys-selector/app-keys-selector"
import $$demoKeysHint from "components/codebox/templates/demo-keys-hint"
import {
  $$warnAboutPrivateKeysDialog,
  $$warnAboutDemoKeysDialog
} from "components/dialog"
import { once } from "global/utils/helpers"
import { viewSdkTracker } from 'util/analytics/gtm'
import { tryInBrowserTracker } from 'util/analytics/gtm'


$doc = $(document)

# Languages match documentation_languages.rb in github.com/ably/docs
convertToCodeMirrorMode = (mode) ->
  return 'text/plain' unless mode?
  switch mode
    when 'javascript' then return 'text/javascript'
    when 'java' then return 'text/x-java' # clike
    when 'python' then return 'text/x-python'
    when 'php' then return 'text/x-php'
    when 'ruby' then return 'text/x-ruby'
    when 'nodejs' then return 'text/javascript'
    when 'objc' then return 'text/x-objectivec'
    when 'swift' then return 'text/x-swift'
    when 'go' then return 'text/x-go'
    when 'csharp' then return 'text/x-csharp'
    when 'cplusplus' then return 'text/x-c++src'
    when 'c' then return 'text/x-c'
    when 'appcelerator' then return 'text/javascript'
    when 'phonegap' then return 'text/javascript'
    when 'html' then return 'text/html'
    when 'json' then return 'application/json'
    when 'sh' then return 'text/x-sh'
    when 'yaml' then return 'text/x-yaml'
    else 'text/javascript'

# @TODO migrate helpers.js.coffee to global/utils/helpers.js and import this
activateButton = ($element) ->
  $element
    .addClass('active')
    .parent()
    .siblings('li')
    .find('button')
    .removeClass('active')

# Show a small code hint top right that shows what language the current block is
addCodeHint = (elem) ->
  $elem = $(elem)
  langHint = DocLang.friendlyLanguage($elem.attr('lang'))
  if langHint
    langHint = 'JS' if langHint == 'Javascript'
    $elem.addClass('has-lang-hint')
    $elem.prepend('<span class="lang-hint" data-js="lang-hint">' + langHint + '</span>')


# append demo hint ui to provided element for not authenticated users
addDemoKeysHint = (element) ->
  $(element).append($$demoKeysHint())
  $('[data-toggle="tooltip"]').tooltip()

# finds a key/placehorder in a code snippet and replaces it per the provided key
# saves current selected in data attribute so that next selections properly
# replaces
replaceKeyInSnippet = (snippet, newKey, oldKey) ->
  $this = $(snippet)
  $code = $('code', snippet)
  currentHTML = $code.html()

  # some demo keys placeholder include special regex chars that need to
  # be escaped. Ex: [API_KEY], {{API_KEY}}
  escapeForRegExp = (str) ->
    str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

  regex = new RegExp(escapeForRegExp(oldKey), 'gm')

  $code.html(currentHTML.replace(regex, newKey))

# update element attribute that keeps selected state
updateKeyHolderWithCurKey = ($holder, curKey) ->
  $holder.data('cur-api-key', curKey)
  $holder.attr('data-cur-api-key', curKey)

# Get initial server-side provided demo key
# filter code snippets that include the provided key
# if authenticated appends appKeysSelector
# else appends warning hint
# On both appends an event listener for copy events and and applies the warnings cb
#
# @param {jQElement} $snippets - a collection of related <pre> tags, multilang blocks
# @param {jQElement} $keyHolder - the data-attribute state machine: keeps selected app key state
# @param {jQElement} $demoKeyProvider - the initial server side provided key for the first replacement
# @param {HTMLElement} uiHolderElement - where to append generated ui from this function
# @param {Object} appKeysSelectorConf - a config object for app-keys-selector plugin
setupAPIKeysRelatedUI = ({
  $snippets,
  $keyHolder,
  $demoKeyProvider,
  uiHolderElement
  appKeysSelectorConf = {
    theme: "dark"
  }
}) ->
  # the attribute is only present if user logged in
  userAuthenticated = $('body').data('user-authenticated') == ''
  $snippetsContainer = $snippets.first().parent()
  $snippetsContainer.attr('tabindex', '0') # make it focusable for keyboard copy
  $globalKeyHolder = $('[data-global-demo-api-key]') # in case the initial key is set once per page
  oldKey = $keyHolder.data('cur-api-key') || $demoKeyProvider.data('demo-api-key')
  newKey = oldKey

  if not $snippets.first().text().includes(oldKey)
    return

  if userAuthenticated
    new AppKeysSelector({
      size: "xs",
      mixClass: "c-codebox__float-top-right",
      onSelectCb: (appKey) ->
        oldKey = $keyHolder.data('cur-api-key') || $demoKeyProvider.data('demo-api-key')
        newKey = appKey.whole_key

        # replace
        $snippets.each ->
          replaceKeyInSnippet(this, newKey, oldKey)
        # update holder (aka the state keeper)
        updateKeyHolderWithCurKey($keyHolder, newKey)
      inject: uiHolderElement
      appKeysSelectorConf...,
    }).init();

    $snippetsContainer.on 'keydown', (e) -> handleCodeWithKeyCopy(e, true, newKey)
  else
    addDemoKeysHint(uiHolderElement)
    $snippetsContainer.on 'keydown', (e) -> handleCodeWithKeyCopy(e, false, oldKey)

# ref to close dialogs
closeDialogs = ->
  $.fancybox.close()

# get user preference about warnings display from localStorage
getWarnAboutKeysPreference = (isPrivate) ->
  storageKeyIdPart = if isPrivate then 'Private' else 'Demo'
  pref = JSON.parse(
    window.localStorage.getItem("warnAbout#{storageKeyIdPart}Keys")
  )

  if pref == null then true else pref

# set user preference about warnings display in localStorage
setWarnAboutPrivateKeysPreference = (isPrivate) ->
  selectorIdPart = if isPrivate then 'private' else 'demo'
  storageKeyIdPart = selectorIdPart[0].toUpperCase() + selectorIdPart.slice(1)

  flag = !$("#js-show-#{selectorIdPart}-keys-warning-checkbox").prop('checked')
  window.localStorage.setItem("warnAbout#{storageKeyIdPart}Keys", flag)

# event handler for copy event in code snippets that have API keys on it
# checks for userPreference on warnings and displays the proper warning
# dialog accordingy. Set userPreference on close if checkbox ticked.
#
# @param {Object} event - the copy event that triggered the handler
# @param {Boolean} isPrivate — if the warning to show is about private keys
#  or demo keys
handleCodeWithKeyCopy = (event, isPrivate, curKey) ->
  # on copy paste
  isKeyboardCopy = ((event.ctrlKey || event.metaKey) && event.key == "c")
  shouldShowWarning = getWarnAboutKeysPreference(isPrivate)
  # only for keyboard events
  selectionIncludesCurKey = (
    event.originalEvent.detail == 0 and
    window.getSelection().toString().includes(curKey)
  )

  if isKeyboardCopy and shouldShowWarning and selectionIncludesCurKey
    $.fancybox.open({
      type: 'html',
      content: if isPrivate then $$warnAboutPrivateKeysDialog else $$warnAboutDemoKeysDialog,
      autoDimensions: false,
      padding: 0,
      helpers: {
        overlay: {
          opacity: 0.3
        }
      },
      beforeShow: ->
        $('[data-close-dialog]').on('click', closeDialogs)
      beforeClose: ->
        $('[data-close-dialog]').off('click', closeDialogs)
        setWarnAboutPrivateKeysPreference(isPrivate)
    })

setupCodeEditor = (element, code, mode, theme = 'default', wrap = false) ->
  # adds theme and proper line-wrapping
  lineWrapping = if wrap then 'CodeMirror--linewrap' else ''
  $element = $(element)
  $element.addClass("CodeMirror CodeMirror--runmode cm-s-#{theme} #{lineWrapping}")
  $parent = $element.parents('.code-block-group')
  # With runmode highlighted markup is injected directly in parent (<pre>) block
  # we wrap it under <code> to keep it semantically correct/valid
  # but also to use <code> as a scroller block wrap. This also allows
  # floating blocks (lang-hints + try buttons) to keep sticked when scrolling
  codeSnippetScroller = $element.find('code')

  if (!codeSnippetScroller.length)
    codeSnippetScroller = $('<code>')

  codeSnippetScroller.appendTo($element)
  # note: runmode does not return an instance
  CodeMirror.runMode(code, mode, codeSnippetScroller.get(0))
  if $parent.length
    addCodeHint(element)
    $parent.addClass("code-block-group--#{theme}")

# Wrap function in a closure, since this is called every single
# time that language changes on documentation (as an example) which
# would create multiple instances and multiple events - memory leaks
addAppKeysUIToCodeBlocksOnce = once(->
  $codeBoxes = $('[data-lang-code-group]') # generated on language.js.coffee
  $codeBoxes.each ->
    $this = $(this)
    $this.addClass('u-relative')
    $this.css outline: '0'
    $thisSnippets = $('pre', $this)

    setupAPIKeysRelatedUI({
      $snippets: $thisSnippets,
      $keyHolder: $this,
      $demoKeyProvider: $('[data-demo-api-key]')
      uiHolderElement: $this,
      appKeysSelectorConf:
        theme: 'gray',
        onMenuShowCb: (instance) ->
          instance.$el.parent().css
            overflow: 'visible'
            "z-index": '1000'
        onMenuHideCb: (instance) ->
          instance.$el.parent().css
            overflow: ''
            "z-index": ''
    })
  return
)

# Adds sytax highlighting to code blocks using CodeMirror
# unless it's already been rendered i.e. cm-rendered
addSyntaxHighlightingToCodeBlocks = ->
  pageScope = $('body').data('scope')
  theme = if (pageScope && pageScope.includes('tutorials-show')) then "darkably" else "default"
  $snippets = $("pre[lang]:visible:not([cm-rendered])")
  $keyHolder = $('[data-demo-api-key]')

  # get snippets with key
  $snippets.each ->
    $this = $(this)
    $this.attr 'cm-rendered', 'true'

    # <code> child node exists only if <pre> was defined with a lang.
    # Otherwise, it will be assumed as plain text.
    if $this.find('code').length
      code = $('code', this).remove().text()
    else
      code = $this.text()
      $this.text('')

    # Initialize as instance of CodeMirror
    mode = convertToCodeMirrorMode($this.attr('lang'))
    setupCodeEditor(this, code, mode, theme)

  # only executed once. see function definition for detail
  addAppKeysUIToCodeBlocksOnce()

# Adds sytax highlighting to code blocks using CodeMirror
# unless it's already been rendered i.e. cm-rendered
addSyntaxHighlightingToBlockQuoteDefinition = ->
  headerHeight = $('[data-js-header]').outerHeight()
  prefix = 'blockquote.definition > p.definition > '

  # Once the first blockquote has rendered, a div is added in, let's be specific though to keep the searches fast
  $(prefix + 'span:visible:not([cm-rendered]), ' + prefix + 'div > span:visible:not([cm-rendered])').each ->
    $this = $(this)
    $this.attr 'cm-rendered', 'true'

    # Collect all links from code block before they are escaped in the
    # CodeMirror conversion
    links = []
    $this.find('a').each ->
      $link = $(this)
      links.push({ href: $link.attr('href'), text: $link.text() })

    # Setup code editor for highlighting
    mode = convertToCodeMirrorMode($this.attr('lang'))
    code = $this.text()

    $this.text('')
    setupCodeEditor(this, code, mode, 'default', true)
    # Add link class to all elements in code block that were links previously
    for link in links
      $this.find('span:contains("' + link.text + '"):not(:has(*))').each ->
        $(this).addClass('method-definition__code-block__link')

    unless links.length is 0
      this.addEventListener 'click', ->
        $target = $(event.target)
        if $target.hasClass('method-definition__code-block__link')
          for link in links
            if $target.text() is link.text
              if link.href[0] is '#' || (window.location.pathname == link.href.split("#")[0])
                hashText = link.href.split("#").pop()
                Navigation.updateLocation("#" + hashText)
                Navigation.scrollToTargetWithOffset("#" + hashText, headerHeight)
              else
                window.location = link.href
              return

    # Group sibling code blocks if they are not already grouped
    if $this.parents('.blockquote-definition').length is 0
      $codeBlocksGroup = $this.nextUntil('*:not(span,br)').addBack()
      wrappingDiv = '<div class="blockquote-definition"/>'
      $codeBlocksGroup.wrapAll(wrappingDiv)

# Syntax highlight and other ui for the snippets
# this setups code editors for channels, signup_complete
# push-notifications, hompeage
setupCodeSnippetsUIForCodeExampleBox = ->
  snippetsSelector = '.code-window-content:not(.no-code-mirror)'
  $snippets = $(snippetsSelector)
  $codewin = $snippets.first().closest('.code-window')
  $codewin.addClass('u-relative')
  theme = $codewin.data('theme') || 'default'
  injectKeysUiTarget = $snippets.first().closest('.code-window')

  if !$snippets?.length
    return

  setupAPIKeysRelatedUI({
    $snippets: $snippets,
    $keyHolder: $codewin,
    $demoKeyProvider: $codewin,
    uiHolderElement: injectKeysUiTarget,
    appKeysSelectorConf: {
      theme: if theme == 'darkably' then 'dark' else 'gray'
    }
  })

  $snippets.each ->
    $this = $(this)
    $code = $('code', this)
    code = $code.text()
    mode = convertToCodeMirrorMode($this.data('mode'))
    lineWrapping = if $codewin.data('wrap') then 'CodeMirror--linewrap' else ''

    $this.addClass("CodeMirror CodeMirror--runmode cm-s-#{theme} #{lineWrapping}")
    # note: runmode doesn't return a cm instance
    CodeMirror.runMode(code, mode, $code.get(0))

  # switching local code window modes for home page
  $('.code-window-menu').on 'click', 'button', ->
    $this = $(this)
    activateButton($this)
    selectedLanguage = $this.text().toLowerCase()

    $this
      .closest('.code-window')
      .find('.code-window-content[data-mode]')
      .addClass('visuallyhidden')
      .end()

    $this
      .closest('.code-window')
      .find('.code-window-content[data-mode="' + selectedLanguage + '"]')
      .removeClass('visuallyhidden')

    displayTryButton = 'none'
    if selectedLanguage == 'javascript'
      displayTryButton = 'block'

    $this
      .parents('.code-window')
      .find('[data-code-window-try-btn]')
      .css('display', displayTryButton)

    false

$tryInBrowserBtn = $('.js-code-example-box').find('[data-id=codebox-try-btn]')
if $tryInBrowserBtn.length
  $tryInBrowserBtn.on 'click', ->
    tryInBrowserTracker()

getSdkLink = (lang) ->
  url = (path) -> "https://github.com/ably/#{path}"

  switch lang
    when 'javascript' then url('ably-js')
    when 'android' then url('ably-java')
    when 'obj-c' then url('ably-cocoa')
    when 'swift' then url('ably-cocoa')
    when 'csharp' then url('ably-dotnet')
    when 'nodejs' then url('ably-js')
    when 'php' then url('ably-php')
    when 'java' then url('ably-java')
    when 'ruby' then url('ably-ruby')
    when 'python' then url('ably-python')
    when 'go' then url('ably-go')
    else null

setupSdkBtn = (lang, langKey) ->
  sdkLink = getSdkLink(langKey)
  $btn = $('.js-code-example-box').find('[data-codebox-sdk-btn]')

  if sdkLink
    $btn.off('click')
    $btn.show().attr('href', sdkLink).find("span").text("View #{lang} SDK on Github")
    $btn.on 'click', ->
      viewSdkTracker({ sdkLanguage: lang.trim() })
  else
    $btn.hide()

# wherever <%= render partial: 'shared/code_example_box' %> is called
# and its _alt variations
#
# @todo refactor and encapsulate everything in a component
# as in use a composition of codebox.js for this specific use case
setupCodeExampleBox = ->
  if $('.js-code-example-box').length > 0
    # Show code block for selected language
    $lis = $('[data-js-code-window-nav] [data-lang]')
    setupSdkBtn('JavaScript', 'javascript')
    $lis.on 'click', ->
      $(this).siblings().removeClass('is-selected')
      $(this).addClass('is-selected')

      if $('.c-codebox__nav').length > 1
        selected_window_nodes = $(this).parents('div')
        .children()
        .closest('[data-js=codebox-nav]')
        .siblings('.code-window')
        .children('.code-window-container').children()
      else
        selected_window_nodes = $("[data-js-code-window-content]")

      langKey = $(this).data('lang')
      lang = $(this).text()
      setupSdkBtn(lang, langKey)

      selected_window_nodes.each ->
        $this = $(this)
        if $this.data('mode') == langKey
          $this.removeClass('u-sr-only')
        else
          $this.addClass("u-sr-only")


# Expose methods to the namespace as needed JIT to add syntax highlighting
window.CodeWindow = {
  addSyntaxHighlightingToCodeBlocks: addSyntaxHighlightingToCodeBlocks,
  addSyntaxHighlightingToBlockQuoteDefinition: addSyntaxHighlightingToBlockQuoteDefinition,
  setupCodeExampleBox: setupCodeExampleBox,
  setupCodeSnippetsUIForCodeExampleBox: setupCodeSnippetsUIForCodeExampleBox
}

# Detect if code examples box is present and setup
$(->
  window.CodeWindow.setupCodeExampleBox()
  window.CodeWindow.setupCodeSnippetsUIForCodeExampleBox()
)
